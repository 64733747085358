import {
  BaseApiModule,
  AuthApiModule,
  LandlordApiModule,
  AvailabilityApiModule,
  SpaceApiModule,
  GuidedTourApiModule
} from './modules'

export class WebApi {
  public readonly base: BaseApiModule
  public readonly auth: AuthApiModule
  public readonly landlord: LandlordApiModule
  public readonly availability: AvailabilityApiModule
  public readonly guidedTour: GuidedTourApiModule
  public readonly space: SpaceApiModule

  constructor(url = '') {
    this.base = new BaseApiModule(url)
    this.auth = new AuthApiModule(url)
    this.landlord = new LandlordApiModule(url)
    this.availability = new AvailabilityApiModule(url)
    this.guidedTour = new GuidedTourApiModule(url)
    this.space = new SpaceApiModule(url)
  }
}

const webApiInstance = new WebApi()

export default webApiInstance
