import BaseApiModule from './BaseApiModule'

export type AvailabilityQuery = {
  return_not_available?: boolean
  min_surface?: number
  max_surface?: number
  surface_field?: string
  filters?: any,
}

export interface ProjectAvailabilityQuery extends AvailabilityQuery {
  project_id?: number
}

export interface BuildingAvailabilityQuery extends AvailabilityQuery {
  project_id?: number
  building_id?: number
}

export interface FloorAvailabilityQuery extends AvailabilityQuery {
  page?: number
  per_page?: number
  project_id?: number
  building_id?: number
  floor_id?: number
  floor_no?: number
}

export interface SpaceAvailabilityQuery extends AvailabilityQuery {
  page?: number
  per_page?: number
  project_id?: number
  building_id?: number
  floor_id?: number
  floor_no?: number
}

export interface SpaceStatisticsQuery extends AvailabilityQuery {
  project_id?: number
  building_id?: number
  floor_id?: number
  floor_no?: number
}

export interface MeasurementRangeQuery {
  range_field_name?: number
  project_id?: number
}

class AvailabilityApiModule extends BaseApiModule {
  readonly controller: string
  readonly v2controller: string

  constructor(url: string) {
    super(url)
    this.controller = 'availability'
    this.v2controller = 'v2/availability'
  }

  parseQuery(query: any) {
    const parsedQueryObject: any = {}
    for (const key in query) {
      if (typeof query[key] !== 'undefined') {
        parsedQueryObject[this.camelToUnderscore(key)] = ['filters', 'order_objects'].includes(key) ? JSON.stringify(query[key]) : query[key]
      }
    }
    return parsedQueryObject
  }

  details(buildingCode: string): string {
    const methodUrl = 'project-details'
    return `${this.baseUrl}/${this.controller}/${methodUrl}/${buildingCode}`
  }

  projectAvailability(query: ProjectAvailabilityQuery) {
    const methodUrl = 'project'
    const queryString = new URLSearchParams(this.parseQuery(query) as any).toString()
    return `${this.baseUrl}/${this.v2controller}/${methodUrl}/?${queryString}`
  }

  buildingAvailability(query: BuildingAvailabilityQuery) {
    const methodUrl = 'building'
    const queryString = new URLSearchParams(this.parseQuery({
      ...query,
      order_by: 'no',
      order: 'ASC'
    }) as any).toString()
    return `${this.baseUrl}/${this.v2controller}/${methodUrl}/?${queryString}`
  }

  floorAvailability(query: FloorAvailabilityQuery) {
    const methodUrl = 'floor'
    const queryString = new URLSearchParams(this.parseQuery({
      ...query,
      order_by: 'sqm',
      order: 'ASC'
    }) as any).toString()
    return `${this.baseUrl}/${this.v2controller}/${methodUrl}/?${queryString}`
  }

  spaceAvailability(query: SpaceAvailabilityQuery) {
    const methodUrl = 'space'
    const queryString = new URLSearchParams(this.parseQuery({
      ...query,
      order_objects: [
        {column: 'building_id', direction: 'ASC'},
        {column: 'floor_id', direction: 'ASC'},
        {column: 'sqm', direction: 'ASC'}
      ]
    }) as any).toString()
    // console.log(queryString);
    return `${this.baseUrl}/${this.v2controller}/${methodUrl}/?${queryString}`
  }

  spaceStatistics(query: SpaceStatisticsQuery) {
    const methodUrl = 'statistics'
    const queryString = new URLSearchParams(this.parseQuery(query) as any).toString()
    return `${this.baseUrl}/${this.v2controller}/${methodUrl}/?${queryString}`
  }
}

export default AvailabilityApiModule
